import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Parse from 'html-react-parser'


/*
 * Parse content to fit with Gatsby
 *
 */
export const ParsePostContentHTML = dataContent => {
	const data = useStaticQuery(graphql`
		query {
			allWordpressWpMedia {
				edges {
					node {
						alt_text
						source_url
						localFile {
							publicURL
							childImageSharp {
								fluid(maxWidth: 1980) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
			}
		}
	`)

	let indexKeyImg = 34552;

	const ParsedHTML = Parse(dataContent, {
		replace: function(domNode) {
			if(domNode.name === 'img') {

				const fluidImg = data.allWordpressWpMedia.edges.filter(media => {

					return media.node.source_url === domNode.attribs.src
				})

				if(fluidImg.length > 0) {
					let srcMedia = (fluidImg[0].node.localFile.childImageSharp)
						? fluidImg[0].node.localFile.childImageSharp.fluid
						: fluidImg[0].node.localFile.publicURL

					indexKeyImg++

					if(fluidImg[0].node.localFile.childImageSharp) {
                  let file = fluidImg[0].node.localFile.childImageSharp.fluid

						return (
							// <Img
							// 	key={indexKeyImg}
							// 	fluid={srcMedia}
							// 	className={`${domNode.attribs.class} gatsby-rendered-img`}
							// 	alt={fluidImg[0].node.alt_text}
                     // />
                     
							<img
								sizes={file.sizes}
								src={file.base64}
								srcSet={file.srcSet}
								alt={fluidImg[0].node.alt_text}
							/>
						)
					} else {
						return (
							<img
								key={indexKeyImg}
								src={srcMedia}
								className={`${domNode.attribs.class} gatsby-rendered-img`}
								alt={fluidImg[0].node.alt_text}
							/>
						)
					}
				}
			}
		}
	})
	  
	return ParsedHTML
}