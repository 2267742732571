import React, { useEffect, useRef, useState } from "react"
import { graphql } from "gatsby"
import { ParsePostContentHTML } from "../../helpers/content.helpers"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const PageBoutique = ({ data }) => {
   const BoutiqueView = useRef(null)
   const [boutiqueLoaded, setBoutiqueLoaded] = useState(false)

   const handler = () => {
      window.ecwid_script_defer = true
      window.ecwid_dynamic_widgets = true

      if (typeof Ecwid != "undefined") window.Ecwid.destroy()
      window._xnext_initialization_scripts = [
         {
            widgetType: "ProductBrowser",
            id: "my-store-39069053",
            arg: ["id=productBrowser"],
         },
      ]

      if (!document.getElementById("ecwid-script")) {
         var script = document.createElement("script")
         script.type = "text/javascript"
         script.src = "https://app.ecwid.com/script.js?39069053"
         script.id = "ecwid-script"
         document.body.appendChild(script)
      } else {
         window.ecwid_onBodyDone()
      }
   }

   useEffect(() => {
      // handler()
      if (BoutiqueView.current && !boutiqueLoaded) {
         handler()
         setBoutiqueLoaded(true)
      }

      return () => {
         setBoutiqueLoaded(false)
      }
   }, [BoutiqueView])

   return (
      <Layout>
         <SEO WPPageID={data.wordpressPage.wordpress_id} />

         <div className="site page">
            <main id="main" className="container container-m">
               <h1>{data.wordpressPage.title}</h1>

               {ParsePostContentHTML(data.wordpressPage.content)}

               <div id="my-store-39069053" ref={BoutiqueView}>
                  <div id="mk-ecwid-loader">
                     <svg
                        xmlns="http://www.w3.org/2000/svg"
                        ariaHidden="true"
                        className="svg-inline--fa fa-spinner-third fa-w-16"
                        data-icon="spinner-third"
                        data-prefix="fad"
                        viewBox="0 0 512 512"
                        width="60"
                        height="60"
                     >
                        <g className="fa-group">
                           <path
                              fill="currentColor"
                              d="M478.71 364.58zm-22 6.11l-27.83-15.9a15.92 15.92 0 01-6.94-19.2A184 184 0 11256 72c5.89 0 11.71.29 17.46.83-.74-.07-1.48-.15-2.23-.21-8.49-.69-15.23-7.31-15.23-15.83v-32a16 16 0 0115.34-16C266.24 8.46 261.18 8 256 8 119 8 8 119 8 256s111 248 248 248c98 0 182.42-56.95 222.71-139.42-4.13 7.86-14.23 10.55-22 6.11z"
                              className="fa-secondary"
                              opacity="0.4"
                           ></path>
                           <path
                              fill="currentColor"
                              d="M271.23 72.62c-8.49-.69-15.23-7.31-15.23-15.83V24.73c0-9.11 7.67-16.78 16.77-16.17C401.92 17.18 504 124.67 504 256a246 246 0 01-25 108.24c-4 8.17-14.37 11-22.26 6.45l-27.84-15.9c-7.41-4.23-9.83-13.35-6.2-21.07A182.53 182.53 0 00440 256c0-96.49-74.27-175.63-168.77-183.38z"
                              className="fa-primary"
                           ></path>
                        </g>
                     </svg>
                  </div>
               </div>
            </main>
         </div>
      </Layout>
   )
}

export default PageBoutique

export const query = graphql`
   query($id: Int!) {
      wordpressPage(wordpress_id: { eq: $id }) {
         title
         excerpt
         content
         wordpress_id
         acf {
            typeformLinkEmbed
         }
      }
   }
`
